import React from "react"
import JsonLd from "./jsonld"
import { useStaticQuery, graphql } from "gatsby"

export default function ActivitiesJson() {
  const { site } = useStaticQuery(query)
  const {
    legalName,

    lsDe,
  } = site.siteMetadata
  return (
    <JsonLd>
      {{
        "@context": "http://schema.org",
        "@type": ["WebPage", "TravelAction", "TouristAttraction"],
        name: "Actvities in Costa Rica during the Tour",
        description:
          "Dance at authentic Latino-Parties and discover Canopy, Volcano-Climbing, Surfing, Bath in Hot Springs and many, many more.",
        publisher: {
          "@type": "Organization",
          name: `${legalName}`,
          url: `${lsDe}`,
        },
      }}
    </JsonLd>
  )
}

const query = graphql`
  query ActivitiesJson {
    site {
      siteMetadata {
        legalName
        lsDe
      }
    }
  }
`
