import React from "react"
import Container from "react-bootstrap/Container"
import ThreeCardsShadow from "../threeCardsShadow"
import { GiJellyfish } from "react-icons/gi"
import { FaUmbrellaBeach } from "react-icons/fa"
import { MdSurfing } from "react-icons/md"

export default function ActivitiesWater() {
  return (
    <Container>
      <h2 className="lstitle text-uppercase">Water Activites</h2>
      <ThreeCardsShadow
        title="Go Surfing"
        icon={<MdSurfing />}
        text="The Carribean and the Pacific Ocean both offer their opportunities for surfing."
        title2="Enjoy the Beach"
        icon2={<FaUmbrellaBeach />}
        text2="Golden, black and white sand beaches. Sunny, endless, quiet or lively. You choose."
        title3="Go Diving"
        icon3={<GiJellyfish />}
        text3="Visit Coral Reefs, fishs, sharks, turtles, manta rays and whales when diving or snorkeling in Costa Rica."
      />
    </Container>
  )
}
