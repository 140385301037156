import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import ImgText from "../components/imgText"
import TextImg from "../components/textImg"
import Cta from "../components/cta"
import Container from "react-bootstrap/Container"
import ActivitiesWater from "../components/activities/water"
import ActivitiesActive from "../components/activities/active"

import ActivitiesAdventure from "../components/activities/adventure"
import ActivitiesDance from "../components/activities/dance"
import ActivitiesFood from "../components/activities/food"
import ActivitiesNature from "../components/activities/nature"
import ActivitiesCulture from "../components/activities/culture"
import ActivitiesCostaRica from "../components/activities/costarica"

import TestimonialCanopy from "../components/testimonials/canopy"
import TestimonialBadBaldi2 from "../components/testimonials/badBaldi2"
import TestimonialCooking from "../components/testimonials/cooking"

import Seo from "../components/Seo/seo"
import ActivitiesJson from "../components/Seo/activitiesJson"
export default function Activities({ data }) {
  return (
    <Layout>
      <Seo
        title="Tour Activities"
        description="Dance at the best parties, dive in jungle lakes next to waterfalls, climb volcanos, relax at the beach. Your native guide Natalia will show you Costa Rica absolutely authenticly."
        image={data.canopy}
      />
      <ActivitiesJson />
      <Container>
        <h1 className="display-1 text-center my-5 lstitle text-uppercase">
          Activities during the Costa Rica Tour
        </h1>
        <h2 className="fw-lighter display-5 text-center mb-5">
          Get a climpse of the activities awaiting you in Costa Rica.
        </h2>
      </Container>
      <TextImg
        title="DANCE at authentic Parties"
        text="Mix with the locals and dance at the best parties. Your guide knows her way around the local dancefloors."
        link=""
        btn=""
        img={data.party}
        imgAlt="Party in Costa Rica"
      />
      <div className="mt-5 mb-5"></div>
      <ActivitiesDance />
      <div className="mt-5 mb-5"></div>
      <ImgText
        title="Join the ADVENTURE"
        text="With Canopy - hundreds of meters of rope - high between the treetops!"
        link=""
        btn=""
        img={data.canopy}
        imgAlt="Canopy in Costa Rica"
      />
      <div className="mt-5 mb-5"></div>
      <TestimonialCanopy />
      <div className="mt-5 mb-5"></div>
      <TextImg
        title="RIDE to Elusive Waterfalls"
        text="Take a horse to bath in hidden lakes with stunning waterfalls - right next to volcanos!"
        link=""
        btn=""
        img={data.horse}
        imgAlt="Horseback Riding in Costa Rica"
      />
      <div className="mt-5 mb-5"></div>
      <ActivitiesNature />
      <div className="mt-5 mb-5"></div>
      <ImgText
        title="BATH in Volcano Powered Hot Springs"
        text="Relax and give your skin and muscles a spa day - at the hot springs of Bad Baldi."
        link=""
        btn=""
        img={data.badBaldi}
        imgAlt="Hot Springs Bad Baldi in Costa Rica"
      />
      <TestimonialBadBaldi2 />
      <div className="mt-5 mb-5"></div>
      <TextImg
        title="LEARN (more) Dances"
        text="Learn more dances and new dance figures with our dance classes during the trip"
        link=""
        btn=""
        img={data.danceclass}
        imgAlt="Dance Class in Costa Rica"
      />
      <div className="mt-5 mb-5"></div>
      <ActivitiesWater />
      <div className="mt-5 mb-5"></div>
      <ImgText
        title="CLIMB Active Volcanos"
        text="Make it to the top and enjoy the view over active volcano craters, wonder about the moonlike structures or fauna and flora in their proximity."
        link=""
        btn=""
        img={data.volcanoCrater}
        imgAlt="View from a Volcano in Costa Rica"
      />
      <ActivitiesAdventure />
      <div className="mt-5 mb-5"></div>
      <Cta
        title="EXPERIENCE new things and learn new tricks"
        subtitle="Add new skills to your skillset "
        link="/booking"
        btn="Level up now"
        bgcolor="#ec6f91"
      />
      <div className="mt-5 mb-5"></div>
      <TextImg
        title="OBSERVE the enormous biodiversity"
        text="In national parks, while snorkeling - or basicly - everywhere around you!"
        link=""
        btn=""
        img={data.lizzard}
        imgAlt="Party in Costa Rica"
      />
      <ActivitiesFood />
      <div className="mt-5 mb-5"></div>
      <ImgText
        title="LEARN to Cook Creole"
        text="Take a cooking class with a Master Chef of the Creole Cuisine and make your friends at home happy with the meals you cook!"
        link=""
        btn=""
        img={data.cooking}
        imgAlt="Creole Cooking Class in Costa Rica"
      />
      <div className="mt-5 mb-5"></div>
      <TestimonialCooking />
      <div className="mt-5 mb-5"></div>
      <ActivitiesCostaRica />
      <div className="mt-5 mb-5"></div>
      <ImgText
        title="EXPLORE Costa Rican History"
        text="Visit historic buildings and learn about colonial and indigen history of Costa Rica."
        link=""
        btn=""
        img={data.history}
        imgAlt="Party in Costa Rica"
      />
      <ActivitiesActive />
      <TextImg
        title="ENJOY magnificent sunsets"
        text="Close to the equator Costa Rica's sunsets are among the the most stunning sunsets in the World"
        link=""
        btn=""
        img={data.sunset}
        imgAlt="Sunset Costa Rica"
      />
      <div className="mt-5 mb-5"></div>
      <ActivitiesCulture />
      <div className="mt-5 mb-5"></div>
      <Cta
        title="It REALLY is THIS AWESOME"
        subtitle="Join Natalia to experience Costa Rica with all its flavours and wonders."
        link="/booking"
        btn="Reserve your spot now"
        bgcolor="#ec6f91"
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    cooking: file(name: { eq: "cooking-creole-costa-rica" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    danceclass: file(name: { eq: "dance-class-costa-rica" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    volcanoCrater: file(name: { eq: "volcano-crater-costa-rica" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    horse: file(name: { eq: "horseback-riding-costa-rica" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    canopy: file(name: { eq: "canopy-costa-rica" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    badBaldi: file(name: { eq: "bad-baldi-costa-rica" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    party: file(name: { eq: "party-costa-rica" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    history: file(name: { eq: "church-costa-rica" }) {
      childImageSharp {
        gatsbyImageData
      }
    }

    lizzard: file(name: { eq: "lizzard-costa-rica" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    sunset: file(name: { eq: "sunset-costa-rica" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`
