import React from "react"
import Container from "react-bootstrap/Container"
import ThreeCardsShadow from "../threeCardsShadow"
import { GiHorseHead, GiHiking } from "react-icons/gi"
import { FaSwimmer } from "react-icons/fa"

export default function ActivitiesActive() {
  return (
    <Container>
      <h2 className="lstitle text-uppercase">Be Active</h2>
      <ThreeCardsShadow
        title="Ride Horses"
        text="Up that hill, in the shadow of volcanos, up to that waterfall."
        icon={<GiHorseHead />}
        title2="Swim"
        text2="Swin in lakes, two different oceans or volcano powered hot springs."
        icon2={<FaSwimmer />}
        title3="Hike"
        icon3={<GiHiking />}
        text3="Explore rainforets, untrottened paths, beaches and city jungles."
      />
    </Container>
  )
}
