import React from "react"
import Container from "react-bootstrap/Container"
import ThreeCardsShadow from "../threeCardsShadow"
import { GiCook } from "react-icons/gi"
import { FaCookieBite, FaCocktail } from "react-icons/fa"

export default function ActivitiesFood() {
  return (
    <Container>
      <h2 className="lstitle text-uppercase">Savor the Tastes</h2>
      <ThreeCardsShadow
        title="Take Cooking Classes"
        icon={<GiCook />}
        text="Take cooking-skills home and suprise your friends at home with the Carribean Cusine."
        title2="Enjoy Fresh Fruit Drinks"
        icon2={<FaCocktail />}
        text2="Mango, Manga, Pineapples, Papaya, Noni, Watermelon, Guava, Tamarindo... Yum!"
        title3="Make your Own Chocolate"
        icon3={<FaCookieBite />}
        text3="Visit an organic cocoa plantation and make your very own chocolate with a traditional recipe."
      />
    </Container>
  )
}
