import React from "react"
import Testimonial from "./testimonials"

export default function TestimonialCooking() {
  return (
    <Testimonial
      quote="Super DELICIOUS and very INTERESTING"
      name="Maya"
      topic="After the Caribbean Cooking Class"
    />
  )
}
