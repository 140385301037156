import React from "react"
import Container from "react-bootstrap/Container"
import ThreeCardsShadow from "../threeCardsShadow"
import { GiCableStayedBridge, GiSloth, GiVolcano } from "react-icons/gi"

export default function ActivitiesAdventure() {
  return (
    <Container>
      <h2 className="lstitle text-uppercase">Go Out on an Adventure</h2>
      <ThreeCardsShadow
        title="On Rope briges"
        icon={<GiCableStayedBridge />}
        text="Explore the jungle, its flora and fauna on rope bridges."
        title2="On Volconos"
        icon2={<GiVolcano />}
        text2="Climb Volcanos, look into craters and enjoy the view."
        title3="In National Parks"
        icon3={<GiSloth />}
        text3="Find rare and endagered species in the many national parks."
      />
    </Container>
  )
}
