import React from "react"
import Testimonial from "./testimonials"

export default function TestimonialCanopy() {
  return (
    <Testimonial
      quote="Absolutely BREATHTAKING"
      name="Mareike"
      topic="About the Canopy tour"
    />
  )
}
