import React from "react"
import Container from "react-bootstrap/Container"
import ThreeCardsShadow from "../threeCardsShadow"
import { GiVillage } from "react-icons/gi"
import { BiHappyBeaming, BiChurch } from "react-icons/bi"

export default function ActivitiesCulture() {
  return (
    <Container>
      <h2 className="lstitle text-uppercase">Enjoy the Culture</h2>
      <ThreeCardsShadow
        title="Native Villages"
        icon={<GiVillage />}
        text="Meet the inhabitants of Villa Maleku, a native Costa Rican tribe."
        title2="Costa Rican Way of Life"
        icon2={<BiHappyBeaming />}
        text2="Learn from the Costa Rican way of life and be kind, helpful and happy."
        title3="Colonial History"
        icon3={<BiChurch />}
        text3="Explore the colonial history of the central-american 'Switzerland'."
      />
    </Container>
  )
}
