import React from "react"
import Container from "react-bootstrap/Container"
import ThreeCardsShadow from "../threeCardsShadow"
import { GiWaterfall, GiRopeCoil, GiBottleVapors } from "react-icons/gi"

export default function ActivitiesNature() {
  return (
    <Container>
      <h2 className="lstitle text-uppercase">Experience Nature</h2>
      <ThreeCardsShadow
        title="Bath next to Waterfalls"
        icon={<GiWaterfall />}
        text="A lake. A waterfall. In the jungle. Your destination in Costa Rica."
        title2="Discover the Heights with Canopy"
        icon2={<GiRopeCoil />}
        text2="Connect to a rope and discover the forest by its treetops. And hundreds of meters of ropes."
        title3="Bath in Hot Springs"
        icon3={<GiBottleVapors />}
        text3="Jump into volcano powered hot springs. The mineral rich water is said be very good for the skin."
      />
    </Container>
  )
}
