import React from "react"
import Testimonial from "./testimonials"

export default function TestimonialBadBaldi2() {
  return (
    <Testimonial
      quote="Simply AMAZING and so RELAXING"
      name="Thomas"
      topic="Sharing his experience with the Hot Springs in Bad Baldi"
    />
  )
}
